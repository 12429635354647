.stop-list-section[data-v-24a9ce] {
  margin-top: 1rem;
}

.stop-list[data-v-24a9ce] {
  list-style-type: none;
}

.stop-list__item[data-v-24a9ce] {
  padding-left: 0;
}

.map[data-v-24a9ce] {
  position: relative;
}

.map__actions[data-v-24a9ce] {
  position: absolute;
  top: 10px;
  right: 10px;
}

.list-enter-active[data-v-24a9ce], .list-leave-active[data-v-24a9ce] {
  transition: all .5s;
}

.list-enter-from[data-v-24a9ce], .list-leave-to[data-v-24a9ce] {
  opacity: 0;
  transform: translateX(30px);
}

img {
  width: 100%;
}

.container[data-v-9d1886] {
  padding-bottom: 1rem;
}

.post-link[data-v-9d1886] {
  color: inherit;
  text-decoration: none;
}
/*# sourceMappingURL=index.16b709ff.css.map */
